export function emailValid(email: string): boolean {
  // email regex
  const regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  return regex.test(email);
}
export function removePhpExtension(inputString: string) {
  // Use a global regular expression to replace all occurrences of '.php' with an empty string
  return inputString.replace(/\.php/g, '');
}

export function formatNumDigits(value: number, numDigits: number): string {
  // TODO - does this need to be locale-aware?
  return value.toLocaleString('en-US', {
    minimumIntegerDigits: numDigits,
    useGrouping: false,
  });
}

export function capitalizeRightOfSeparator(inputString: string, separator: string) {
  const parts = inputString.split(separator);
  if (parts.length === 2) {
    const leftPart = parts[0];
    const rightPart = parts[1].toUpperCase();
    return `${leftPart}${separator}${rightPart}`;
  }
  return inputString; // Return unchanged if there are not exactly two parts.
}
